<template>
    <div class="flex items-start gap-x-2 md:gap-x-3 gap-y-3 md:flex-row justify-between py-2.5 px-2 md:px-7 md:items-center rounded-md backdrop-blur bg-white/10 text-sm md:text-base">
        <div class="flex flex-col md:flex-row text-center md:text-left items-center gap-2.5">
            <UIcon name="i-heroicons-outline-star" class="w-11 h-11 shrink-0 text-slate-300" />
            <div class="text-slate-200 font-bold">Over 10 Years of Experience</div>
        </div>
        <div class="flex flex-col md:flex-row text-center md:text-left items-center gap-2.5">
            <UIcon name="i-heroicons-outline-wrench" class="w-11 h-11 shrink-0 text-slate-300" />
            <div class="text-slate-200 font-bold">Over 1,000 Vehicles Serviced</div>
        </div>
        <div class="flex flex-col md:flex-row text-center md:text-left items-center gap-2.5">
            <UIcon name="i-heroicons-outline-chat-bubble-oval-left-ellipsis" class="w-11 h-11 shrink-0 text-slate-300" />
            <div class="text-slate-200 font-bold">More Than 500 Honest Reviews</div>
        </div>
    </div>
</template>
